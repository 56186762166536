import {UPDATE_CR_LINK} from '../../actions/crLink';

export default function (state = {}, action = '') {

    switch (action.type) {
        case UPDATE_CR_LINK:
            return action.payload;
        default:
            return state;
    }
}
