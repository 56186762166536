import {UPDATE_FILTER, CLEAR_FILTER} from '../../actions/selectedFilters';

export default function (state = {}, action = '') {

    switch (action.type) {
        case UPDATE_FILTER:
            return {
                ...state,
                [action.payload.categoryId]: action.payload
            }
        case CLEAR_FILTER:
            return {
                ...state,
                [action.payload]: {
                    categoryId: action.payload,
                    filters: {}
                }
            }
        default:
            return state;
    }
}
