import React, {Suspense} from 'react';

import ErrorBoundary from "../errorBoundary";
import style from "../../modules/ProductsAndFilters.module.scss";
import container from "../../modules/Container.module.scss";

import QuickLinksPlaceholder from '../placeholder/quickLinks';
import FiltersPlaceholder from '../placeholder/filters';
import ProductsPlaceholder from '../placeholder/products';
import QuickLinks from "../quickLinks";

const Filters = React.lazy(() => import('../filters'));
const Products = React.lazy(() => import('../products'));

/**
 * main App component
 */
const App = () => {

    return (
        <ErrorBoundary>
            <div className={style.productsAndFiltersContainer}>
                <div className={container.container}>
                    <Suspense fallback={<QuickLinksPlaceholder/>}>
                        <QuickLinks/>
                    </Suspense>
                    <Suspense fallback={<FiltersPlaceholder/>}>
                        <Filters/>
                    </Suspense>
                    <Suspense fallback={<ProductsPlaceholder/>}>
                        <Products/>
                    </Suspense>
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default App;