import _ from "lodash";

//set the environment
const environmentSwitch = process.env.REACT_APP_MODE || process.env.NODE_ENV;

export const parseFilters = (filters, offset = null) => {
    switch(environmentSwitch){
        case 'production':
            return asFormData(filters, offset);
        default:
            return asJson(filters, offset);
    }
};

const asJson = (filters, offset) => {
    let parsedFilters = {};

    if(filters.categoryId){
        parsedFilters.categoryId = filters.categoryId
    }

    if(filters.keywords){
        parsedFilters.keywords = filters.keywords
    }

    if(offset){
        parsedFilters['offset'] = offset;
    }

    if(filters.filters) {
        Object.keys(filters.filters).forEach(key => {
            parsedFilters[key] = filters.filters[key].value
        })
    }

    if(filters.products){
        parsedFilters = filters;
    }

    return parsedFilters;
}

const asFormData = (filters, offset) => {
    const form_data = new FormData();

    if(filters.categoryId){
        form_data.append('categoryId', filters.categoryId);
    }

    if(filters.keywords){
        form_data.append('keywords', filters.keywords);
    }

    if(offset){
        form_data.append('offset', offset);
    }

    if(filters.filters) {
        Object.keys(filters.filters).forEach(key => {
            form_data.append(`${key}`, filters.filters[key].value);
        })
    }

    if(filters.products){
        form_data.append('products', filters.products);
    }

    return form_data;
}

export const updateFilterStore = (selectedFilters, item, categoryId) => {
    let filter;
    let updateState = false;

    if(selectedFilters){
        filter = _.cloneDeep(selectedFilters);
    }
    else{
        filter = {
            categoryId: categoryId,
            filters: {}
        };
        updateState = true;
    }

    //add / remove from filter
    if(filter.filters[item.name]){
        if(!item.checked || item.value === ''){
            delete filter.filters[item.name];
            updateState = true;
        }
        else {
            if(filter.filters[item.name].value !== item.value){
                filter.filters[item.name] = {
                    ...filter.filters[item.name],
                    value: item.value
                };
                updateState = true;
            }
        }
    }
    else{
        if(item.checked){
            filter.filters[item.name] = {
                value: item.value,
                label: item.label
            };
            updateState = true;
        }
    }

    return updateState ? filter : null;
}

export function backForwardRefreshNavigation() {
    const perfEntries = performance.getEntriesByType('navigation');

    return (
        perfEntries.length && (
            perfEntries[0].type === 'back_forward' ||
            perfEntries[0].type === 'reload'
        )
    );
}

export function getSelectedFilterCount (renderFilters) {
    const renderFiltersCount = Object.keys(renderFilters).length;

    if (renderFiltersCount > 0) {
        return ` (${renderFiltersCount})`
    }
}

export class Viewport {
    window = window;
    document = document;

    getViewportWidth() {
        /**
         * We want the min of the window inner width and the screen's width here.
         * On rotate the window.innerWidth is too large.
         * On first app boot locally, going from desktop to mobile emulation
         * the screenwidth is too large but window.innerWidth is correct.
         *
         * Taking the min of these two values looks like it's always correct.
         */
        let screenWidth = 0;
        if (this.window && this.window.innerWidth) {
            screenWidth = Math.min(this.window.innerWidth, screenWidth);
        }
        return Math.max(this.document.documentElement.clientWidth, screenWidth);
    }
}