export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';

export const toggleBodyContainerClass = () => {
    if (document.getElementById('body-container')) {
        document.getElementById('body-container').classList.toggle('filter-options-open');
    }
}

export function toggleFilters() {
    toggleBodyContainerClass();
    return {
        type: TOGGLE_FILTERS
    }
}