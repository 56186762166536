import clothes2order from './clothes2order';

//set the environment
const environmentSwitch = process.env.REACT_APP_MODE || process.env.NODE_ENV;

export const get = (baseApiUrl, url, query) => {
    // no env switch required for GET requests.
    return clothes2order(baseApiUrl)
        .get(url, {params: query})
        .then(response => {
            return response.data;
        });
}

export const post = (baseApiUrl, url, formData, query) => {
    switch (environmentSwitch) {
        case 'production':
            return clothes2order(baseApiUrl)
                .post(url, formData, {params: query})
                .then(response => {
                    return response.data;
                });
        default:
            return clothes2order(baseApiUrl)
                .get(url, {
                    params: {
                        ...formData,
                        ...query
                    },
                })
                .then(response => {
                    return response.data;
                });
    }
}

export default post;
