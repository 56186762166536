import React from 'react';

export default () => {
    return (
        <div style={{
            marginBottom: 20
        }}>
            {
                [0,1,2,3,4,5,6].map(key => {
                    return <div
                        key={key}
                        style={{
                            display: 'inline-block',
                            width: 185,
                            height: 33,
                            borderRadius: 3,
                            background: 'rgba(0, 0, 0, 0.05)',
                            margin:'0 20px 10px 0'
                    }}> </div>;
                })
            }
            <div style={{
                height:65,
                border:'1px solid #ddd',
                borderRadius: 5,
                marginBottom: 20
            }}> </div>
        </div>
    )
}