export const UPDATE_FILTER = 'UPDATE_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';

export function updateFilter(filter){

    return {
        type: UPDATE_FILTER,
        payload: filter
    }
}

export function clearFilter(category){

    return {
        type: CLEAR_FILTER,
        payload: category
    }
}
