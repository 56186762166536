import React from 'react';

import card from '../../modules/Cards.module.scss';
import container from '../../modules/Container.module.scss';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div className={container.container}>
                    <div className={card.card}>
                        <div className={card.cardBody}>
                            <h2>Something went wrong.</h2>
                            <details style={{whiteSpace: 'pre-wrap'}}>
                                {this.state.error && this.state.error.toString()}
                                <br/>
                                {this.state.errorInfo.componentStack}
                            </details>
                        </div>
                    </div>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default Index;