let values = {
    categoryId: 0,
    apiUrl: '',
    shareLink: '',
    scrollOffset: 0,
    ...window.outerApplication,
    isDebug: true,
    productionUrls: false,
    GaTrackingId: 'UA-317176-3',
    assetUrl: 'https://www.clothes2order.com', //images
    endpoints: {}
};

//set the environment
const environmentSwitch = process.env.REACT_APP_MODE || process.env.NODE_ENV;

switch(environmentSwitch){
    case 'production':
        values.productionUrls = true;
        values.isDebug = false;

        values.endpoints.products = './ajax.cat_refine_products_json.php';
        values.endpoints.filters = './ajax.cat_refine_json.php';
        values.endpoints.quickLinks = './ajax.quicklinks.php';
        break;
    default:
        values.endpoints.products = './samples/cat_refine_products.json';
        values.endpoints.filters = './samples/cat_refine.json';
        values.endpoints.quickLinks = './samples/quicklinks.json';
        break;
}

export default values;
