import {combineReducers} from 'redux';

import SelectedFilters from './selectedFilters';
import ToggleFilters from "./toggleFilters";
import CrLink from "./crLink";

const rootReducer = combineReducers({
    selectedFilters: SelectedFilters,
    filtersVisible: ToggleFilters,
    crLink: CrLink,
});

export default rootReducer;
