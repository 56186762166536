import defaults from "../configuration/defaults";

import ReactGA from "react-ga";

export const initGa = () => {
    try {
        ReactGA.initialize(defaults.GaTrackingId);
    } catch (e) {}
}

export const onclickGAEvent = (args = {}) => {
    if (defaults.isDebug) {
        return console.log(args);
    } else {
        ReactGA.event({
            category: args.category || 'LP Interaction',
            action: args.action || 'Image Link',
            label: args.label || 'Some product!'
        });
    }
}