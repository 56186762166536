import React from 'react';

export default () => {
    return (
        <div style={{
            marginBottom: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "12px",
        }}>
            {
                [0,1,2,3,4,5,6,7].map(key => {
                    return <div
                        key={key}
                        style={{
                            display: 'inline-block',
                            width: 64,
                            height: 106,
                            borderRadius: 3,
                            background: 'rgba(0, 0, 0, 0.05)',
                            margin:'0 6px 10px 6px',
                            padding:'8px 10px'
                    }}> </div>;
                })
            }
        </div>
    )
}