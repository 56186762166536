import React from 'react';

//redux
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import defaults from '../../configuration/defaults';

import {Scrollbars, CategoryCard} from "@quayside/c2o-elements";

import {backForwardRefreshNavigation} from '../../services/utilities';
import {fetchQuickLinks} from "../../services/filtersService";

import {initGa, onclickGAEvent} from '../../middleware/googleAnalytics';

import spacing from '../../modules/Spacing.module.scss';
import styles from '../../modules/QuickLinksContainer.module.scss';

import {updateCrLink} from "../../actions/crLink";
import QuickLinksPlaceholder from '../placeholder/quickLinks';
import {isNumber} from "lodash";

export class QuickLinks extends React.Component {

    initGa = initGa;
    onclickGAEvent = onclickGAEvent;
    defaults = defaults;
    backForwardRefreshNavigation = backForwardRefreshNavigation;
    fetchQuickLinks = fetchQuickLinks;
    location = location;
    window = window;
    history = history;
    ref = React.createRef();
    quickLinksRef = React.createRef();

    constructor(props) {
        super(props);

        this.categoryId = defaults.categoryId;
        this.apiUrl = defaults.apiUrl;

        this.state = {
            isLoading: true,
            selectedQuickLink: null,
            quickLinks: []
        };
    }

    componentDidMount() {
        this.initGa();

        if (this.defaults.shareLink) {
            this.props.updateCrLink(this.defaults.shareLink);
        }

        // Only load quick links for a valid category id.
        // CategoryId can be 'search'...
        if (isNumber(this.categoryId) && Math.sign(this.categoryId)) {
            const query = {
                categoryId: this.categoryId,
            };
            this.fetchQuickLinks(this.apiUrl, query).then(this.fetchQuickLinkSuccess);
        } else {
            this.setState({
                isLoading: false,
                quickLinks: []
            });
        }
    }

    scrollToBottom = () => {
        const ref = this.quickLinksRef?.current;
        // If the ref is not available, don't scroll
        if (!ref) {
            return null;
        }

        const rect = ref.getBoundingClientRect();
        // Bottom position of the element from the top of the dom + the amount the window has scrolled down.
        // Also account for any scroll offsets due to sticky headings
        let top = rect?.bottom + this.window.scrollY + this.defaults.scrollOffset;
        // Then add 8, so it's positioned nicely
        top = top + 8;
        this.window.scrollTo({ top: top, behavior: "smooth" });
    }

    fetchQuickLinkSuccess = (result) => {
        this.setState({
            isLoading: false,
            quickLinks: result.data
        });
    }

    onClick = (id, text, link, linkType) => {
        this.onclickGAEvent({
            eventAction: 'Quick Link Clicked',
            eventLabel: `${id} : ${text} : ${link}`
        });
        if (linkType === 'static') {
            this.window.location.href = link;
            return;
        }

        // Add or replace a cr link to the current URL.
        const url = new URL(this.location);
        url.searchParams.set("cr", link);
        this.history.pushState({cr: link}, "", url);

        this.props.updateCrLink(link);
        this.setState({
            selectedQuickLink: id
        }, this.scrollToBottom);
    }

    renderQuickLink = (quickLink) => {
        return (
            <div key={'quickLink-' + quickLink.id}
                 data-testid={'quick-link'}
                 data-id={quickLink.id}
                 data-link={quickLink.link}
                 data-linktype={quickLink.type}
            >
                <CategoryCard
                    id={quickLink.category_id}
                    text={quickLink.text}
                    image={quickLink.image}
                    link={quickLink.link}
                    linkType={quickLink.type}
                    // active={selectedQuickLink === quickLink.id}
                    onClick={this.onClick}
                />
            </div>
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div data-testid={"quick-links-placeholder"}>
                    <QuickLinksPlaceholder/>
                </div>
            );
        }

        return (
            <div data-testid={"quick-links-grid"} ref={this.quickLinksRef}>
                <div className={`${spacing.mb3} ${styles.quickLinksOuter}`} data-testid={"quick-links-container"}>
                    <Scrollbars
                        data-testid={"quick-links-scrollbars"}
                        ref={this.ref}
                        autoHeight
                        autoHeightMax={130}
                    >
                        <div className={`${styles.quickLinksInner}`} data-testid={"quick-links-inner"}>
                        {this.state.quickLinks.map(this.renderQuickLink)}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

QuickLinks.propTypes = {
    updateCrLink: PropTypes.func,
    crLink: PropTypes.string,
}

function mapStateToProps(state) {
    return {
        crLink: state.crLink,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateCrLink
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickLinks);
