import React from 'react';

export default () => {
    return (
        <div style={{
            marginBottom: 20
        }}>
            {
                [0,1,2].map(key => {
                    return <div
                        key={key}
                        style={{
                            display: 'inline-block',
                            width: 280,
                            height: 415,
                            borderRadius: 3,
                            background: 'rgba(0, 0, 0, 0.05)',
                            marginRight:20
                    }}> </div>;
                })
            }
        </div>
    )
}