import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import App from './components/app';
import {getStore} from './store';

//add the redux store as a provider
ReactDOM.render(
    <Provider store={getStore()}>
        <App/>
    </Provider>
    , document.querySelector('#product-lister-container'));
