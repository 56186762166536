import {get, post} from '../api/wrapper';
import {parseFilters} from "./utilities";

import defaults from '../configuration/defaults';

export const fetchFilters = (selectedFilters, apiUrl, query = {}) => {
    return post(
        apiUrl,
        defaults.endpoints.filters,
        parseFilters(selectedFilters),
        query
    );
};

export const fetchQuickLinks = (apiUrl, query = {}) => {
    return get(
        apiUrl,
        defaults.endpoints.quickLinks,
        query
    );
};

export default fetchFilters;