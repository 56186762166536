import axios from 'axios';

//set the environment
const environmentSwitch = process.env.REACT_APP_MODE || process.env.NODE_ENV;

const headerContentType = () => {
    switch(environmentSwitch){
        case 'production':
            return 'x-www-form-urlencoded';
        default:
            return 'application/json';
    }
}

const clothes2order = (baseURL = '/') => {
    return axios.create({
        baseURL: baseURL,
        headers: {
            'Content-Type': headerContentType()
        }
    });
};

export default clothes2order;
